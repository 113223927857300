import React from 'react'
import { Seo } from '../components'

import { SubscribeBlock } from '../components/home/subscribe-block'

const HomePage = props => {
  const title = 'Subscribe'
  return (
    <div>
      <Seo title={title} location={props.location} />
      <SubscribeBlock />
    </div>
  )
}

export default HomePage
